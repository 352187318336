<template>
  <DIV>
    <router-view  />

  </DIV>
</template>

<script>

export default {
  name: 'App',
  components: { },
  methods: {
    auth (user) {
      console.log(user);

    }
  }
}
</script>

<style>
@import '@/assets/css/bc-colors.css';
@import '@/assets/css/nav.css';
@import '@/assets/css/description.css';
@import './f7.css';
@import './fade.css';

@import '@/assets/css/colors.css';

.main-container {
  /*  height: calc(100vh - var(--ep-menu-item-height) - 3px);*/
}
.fullwidth
{
  width:100%;

}
body .el-button
{
  font-size:16px;
  height:50px;
}
.button-wrapper + .button-wrapper
{
  display:flex;
  margin-top:10px;
}
.el-message
{
  background-color: var(--botterfly-theme-text-color);
  border-color: var(--botterfly-theme-button-color);
}
.el-message .el-icon.el-message-icon--success
{
  color: var(--botterfly-theme-bg-color);
}
.el-message .el-message__content
{
  color: var(--botterfly-theme-bg-color);
}
#app .el-form--inline .el-form-item
{
  margin-right: 0px;
}
body .el-message.is-plain
{
  background-color: var(--botterfly-theme-text-color);
  border-color: var(--botterfly-theme-bg-color);
}
body .el-message--success .el-message__content
{
  color: var(--botterfly-theme-bg-color);
}
body .air-datepicker-body--day-name
{
  color: var(--botterfly-theme-text-color);
}
body .air-datepicker-cell.-year-.-other-decade-, .air-datepicker-cell.-day-.-other-month-
{
  filter:brightness(0.4);
}
</style>
