<template>
  <div style="padding:20px;">
  <h3 style="text-align:center;">
    Платеж был выполнен успешно!
  </h3>
    <p style="text-align:center;">
      Через 2 минуты ваш аккаут будет активирован.
    </p>
        <p style="text-align:center;">Перейдите по <a style="color: #86B0FF;" href="https://t.me/TheSoulMatrixBot/calc">ссылке</a> в Telegram и получите расшифровку своей Матрицы души.</p>
  </div>
</template>
<script>
export default {

};
</script>
