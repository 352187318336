<template>
  <span style="text-align:center;" v-if="botLogin">
        <p style="padding:5px; text-align:center;">Войдите через Telegram для подтверждения оплаты</p>
    <telegram-login-temp
        mode="callback"
        :telegram-login="botLogin"
        @callback="auth" />
  </span>
</template>
<script>
import { telegramLoginTemp } from 'vue3-telegram-login'

export default {
  name: 'App',
  components: {telegramLoginTemp },
  data() {
    return {
      botLogin:process.env.VUE_APP_BOT_NAME,
    }
  },
  mounted() {
    //this.botLogin=process.env.VUE_APP_BOT_NAME;
  },
  methods: {
    auth (user) {
      console.log(user);
        fetch(process.env.VUE_APP_SITE_URL+'pay_page/url/'+user.id+'/?initData='+encodeURIComponent(JSON.stringify(user))).then(data=>data.json()).then(data=>{
          if (data.success)
          {
            window.location=data.url;
          }
          else
          {
            alert(JSON.stringify(data));
          }
        })
    }
  }
}
</script>
