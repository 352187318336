<template>
  <span>
    Провал!!
  </span>
</template>
<script>
export default {

};
</script>
