import {
    createRouter,
    createWebHistory,
    createWebHashHistory
} from 'vue-router'
import Vue from 'vue'


import main from '@/components/pages/mainPage';
import success from '@/components/pages/successPage';
import fail from '@/components/pages/failPage';
const routerHistory = createWebHashHistory()



const routes = createRouter({
    history: routerHistory,
    routes: [

        {path:'/success',component:success,title: 'Успех оплаты',name:'success'},
        {path:'/fail',component:fail,title: 'Провал оплаты',name:'fail'},
        {path:'/',component:main,title:"Оплата бота",name:"main"}

    ],
    //        {path:'/results/:id/:res_id',component:results,name:"results"}
    redirect: [

    ]
});

/**/
export default routes
